<template>
  <div>
    <b-form-group>
      <b-form-radio-group v-model="units" size="sm"
                          :options="unitOptions">
      </b-form-radio-group>
    </b-form-group>
    <!--    <p class="instruction">Enter pace and distance to get total time:</p>-->
    <b-form-row>
      <b-col md="4" cols="6">
        <b-form-group>
          <label for="pace">Pace ({{ (units == 'miles' ? ' mins/mile' : ' mins/km') }})</label>
          <b-form-input id="pace" placeholder="mm:ss" v-model="pace" :state="validPace" :formatter="checkTimeInput"/>
          <small v-if="validPace==false">Enter pace as e.g. '9:05'</small>
        </b-form-group>
      </b-col>
      <b-col md="4" cols="6">

        <label for="distance">Distance ({{ units }})</label>
        <b-input-group>
          <template #prepend>
            <b-dropdown size="sm" variant="outline-secondary">

              <b-dropdown-item v-for="d in distances" :key="d.name" @click="setDistance(d.distance)">{{ d.name }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-form-input id="distance" placeholder="0" v-model="distance" type="text"
                        :state="validDistance" :formatter="checkNumericInput"/>
        </b-input-group>
      </b-col>


    </b-form-row>

    <template v-if="time">
      <b-form-row>
        <b-col cols="12">
          <div class="result mt-1">Your time would be: <b>{{ time }}</b></div>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12">

          <div class="mt-2 mb-1" style="font-size:0.8rem">Times for standard distances at this pace:</div>
          <table class="times-table">
            <tr v-for="t in times" :key="t.name">
              <td>{{ t.name }}</td>
              <td>{{ t.time }}</td>
            </tr>
          </table>

        </b-col>
      </b-form-row>
    </template>


  </div>


</template>
<script>

export default {
  data: () => ({
    units: "miles",
    distance: '',
    //pace: '',
    pace: '',
    time: '',
    times: []
  }),
  watch: {
    'units': function () {
      this.calc();
    },

    'pace': function () {
      this.calc();
    },
    'distance': function () {
      this.calc();
    }
  },
  activated() {
    if(sessionStorage.getItem('pace')){
      this.pace=sessionStorage.getItem('pace');
    }
    if(sessionStorage.getItem('units')){
      this.units=sessionStorage.getItem('units');
    }
  },

  computed: {
    unitName() {
      if (this.units == "miles") {
        return "mile"
      } else if (this.units == "km") {
        return "km"
      } else {
        return "metre"
      }
    },
    validDistance() {
      if (!this.distance) return null;
      return this.isValidDistance(this.distance);
    },

    validPace() {
      if (!this.pace) return null;
      return this.isValidPace(this.pace);
    }

  },

  mounted() {

  },
  methods: {
    inputsValid: function () {
      return this.pace && this.validPace && this.distance && this.validDistance
    },

    checkTimeInput: function (val) {
      return val.replace(/[^0-9.:]/g, '');
    },
    setDistance(km) {
      if (this.units == "miles") {
        this.distance = this.round(km / this.KM_PER_MILE, 3);
      } else {
        this.distance = this.round(km, 3);
      }

    },


    calc: function () {
      if (this.inputsValid()) {
        var index = this.pace.indexOf(":");
        //var secsPerDistanceUnit = 0;
        if (index !== -1) {
          if (index === this.pace.length - 1) return;//it's the last character
          var parts = this.pace.split(":");
          var mins = 0;
          var secs = 0.0;
          mins = parseInt(parts[0], 10);
          if (parts.length > 1) {
            secs = parseFloat(parts[1], 10);
          }
          var secsPerDistanceUnit = (mins * 60) + secs;
          var speed = this.round(3600 / secsPerDistanceUnit, 2);

          if (speed > 0) {

            this.time = this.calcTime(secsPerDistanceUnit, this.distance);
            this.times = [];
            let allDistances=this.trackDistances.concat(this.distances);
            for (var i=0; i < allDistances.length; i++) {
              var d = allDistances[i];
              var distance=d.distance;
              if (this.units == "miles") {
                distance = this.round(distance / this.KM_PER_MILE, 3);
              } else {
                distance = this.round(distance, 3);
              }
              console.log(d);
              this.times.push({name: d.name, time: this.calcTime(secsPerDistanceUnit, distance,false)});
            }
            //console.log(this.times);

          }
        }

      }
    },

    calcTime: function (secsPerDistanceUnit, distance,alwaysShowHours=true) {
      var totalSecs = secsPerDistanceUnit * distance;
      var resultSecs = this.round(totalSecs % 60, 1);
      var totalMins = Math.floor(totalSecs / 60);
      var resultMins = totalMins % 60;
      var totalHours = Math.floor(totalMins / 60);
      var resultHours = totalHours;
      if (resultSecs == 60) {
        resultSecs = 0;
        resultMins++;
      }
      if (resultMins == 60) {
        resultMins = 0;
        resultHours++;
      }
      let output= resultMins.toString().padStart(2, '0') + ":" + resultSecs.toString().padStart(2, '0');
      if(alwaysShowHours){
         return resultHours + ":" +output;
      } else {
        if(resultHours>0){
          return resultHours + ":" +output;
        } else {
          return output;
        }
      }
    }
  }

}

</script>
<style type="text/scss" scoped>

  table.times-table {
    width:50%;
    border: 1px dotted black;


  td {
    padding: 3px;
    border: 1px dotted black;
    font-size: 0.8rem;
  }
  }

</style>