<template>
  <b-container fluid="sm" class="my-sm-2 px-0">
    <b-row class="justify-content-center align-self-center">
      <b-col cols="12" sm="6">

        <b-form>
          <div v-if="calcType" class="main rounded-lg shadow-sm">
            <h5 class="mb-4 mt-3 main-title">John's Running Calculator</h5>
            <label>Select calculation:</label>
            <b-form-select id="selector" v-model="calcType" text-field="name" size="md" @change="logType">
              <b-form-select-option v-for="opt in options" :key="opt.value" :value="opt.value" :title="opt.text" v-b-tooltip.hover>{{opt.name}}</b-form-select-option>

            </b-form-select>
            <p class="description mt-2 mb-3 text-muted">{{description}}</p>
            <div id="calculator">
            <keep-alive>
            <component :is="component"></component>
            </keep-alive>
              </div>
          </div>

        </b-form>
      </b-col>
    </b-row>

  </b-container>

</template>
<script>

import PaceCalculator from './components/PaceCalculator'
import TimeCalculator from './components/TimeCalculator'
import DistanceCalculator from './components/DistanceCalculator'
import Conversions from './components/Conversions'
import UnitConverter from "./components/UnitConverter";
import PaceConverter from "./components/PaceConverter";
import HeartRate from "./components/HeartRate";
import AgeGradingCalculator from "./components/AgeGradingCalculator";
export default {
  components: {
    PaceCalculator, TimeCalculator, DistanceCalculator,Conversions,UnitConverter,PaceConverter,HeartRate,AgeGradingCalculator
  },

  data: () => ({
    calcType: 'pace',
    options: [
      //{value: null, text: "Select calculator..."},
      {value: 'pace', name:"Pace Calculator",text: 'Enter distance and target time to get required pace',component:PaceCalculator,path:"/pace"},
      {value: 'time', name:"Time Calculator", text: 'Enter pace and distance to get total time',component:TimeCalculator,path:"/time"},
      {value: 'distance', name:"Distance Calculator", text: 'Enter pace and time to get distance run',component:DistanceCalculator,path:"/distance"},
      {value: 'ageGrading', name:"Age Grading Calculator", text: 'Calculate your age grading from a race time',component:AgeGradingCalculator,path:"/age-grading"},
      {value: 'conversions', name:"Pace-Speed Converter", text: 'Convert between pace and speed',component: Conversions,path:"/conversions"}
      ,
      {value: 'unit-converter', name:"Miles <-> Kilometres", text: 'Convert between miles and kilometres',component: UnitConverter,path:"/unit-converter"},
      {value: 'pace-converter', name:"Mins/mile <-> Mins/km", text: 'Convert pace between mins/miles and mins/km',component: PaceConverter,path:"/pace-converter"},
      {value: 'heart-rate-percentages', name:"Heart Rate Percentages", text: 'Display heart rate training percentages',component: HeartRate,path:"/heart-rate"},
    ],

  }),
  computed: {
    description:function(){
      return this.options.find(x => x.value=== this.calcType).text;
    },
    component:function(){
      return this.options.find(x => x.value=== this.calcType).component;
    }
  },
  mounted() {


  },
  methods: {
    logType: function () {
      console.log("Type selected = " + this.calcType);
    }
  }

}

</script>
<style type="text/scss">
body{
  background-color: #eeeeee;
}

.description{
line-height: 1.2;
}
.main-title{
  font-size: 1.5rem;
  color:darkgreen;
}

.main{
  background-color: lightblue;
  padding:20px;
  border: solid 2px silver;
  min-height:95vh;
}

#selector{
  color:teal;
  font-size: 1rem;
 margin-bottom:0;
}

#calculator{
  margin-top:1.5em;
}
.instruction {
  font-size:18px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5em;
  color:teal;
}

.small-lbl {
  font-size: 14px;
}

.result{
  font-size:18px;

}
.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
  padding-right: 0;
}


</style>